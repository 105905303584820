<template>
  <div class="admin-list">
    <h4 class="list-title">Пользователи</h4>
    <div class="admin-navbar align-left">
      <Search @search="search"/>
    </div>
    <div class="admin-table">
      <table>
        <thead>
        <tr>
          <th class="key">ПИН</th>
          <th>ФИО</th>
          <th>Номер телефона</th>
          <th>Электронная почта</th>
          <th></th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td class="key">12345678901234</td>
          <td>Бекжигитова Раяна Бексултановна</td>
          <td>777555666</td>
          <td>rayana@gmail.com</td>
          <td>
            <div class="action-buttons">
              <router-link to="/admin/users/1" class="action-button"><i class="bi-info-square"></i></router-link>
            </div>
          </td>
        </tr>
        <tr>
          <td class="key">12345678900987</td>
          <td>Канатбеков Дастан Жумгалбекович</td>
          <td>777555999</td>
          <td>dastan@gmail.com</td>
          <td>
            <div class="action-buttons">
              <router-link to="/admin/users/2" class="action-button"><i class="bi-info-square"></i></router-link>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import Search from '../../../components/admin/Search'
export default {
  components: {
    Search
  },
  methods: {
    search(value) {
      console.log(value)
    }
  }
}
</script>
<style>
@import '../../../assets/styles/admin/list.css';
@import '../../../assets/styles/admin/navbar.css';
@import '../../../assets/styles/admin/table.css';
</style>